@import "scss/variables.scss";
@import "scss/typography.scss";

.categoryLabel {
  @extend %bodyText;
  font-weight: 700;

  &__orange {
    color: $orange;
  }
  &__blue {
    color: $blue;
  }
  &__purple {
    color: $purple;
  }
}
