@import "scss/variables.scss";

%h4Text {
  font-family: "Eudoxus Sans";
  font-size: 32px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.5px;
}

%h5Text {
  font-family: "Eudoxus Sans";
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
}

%h6Text {
  font-family: "Eudoxus Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: 1.33;
}

%bodyText {
  font-family: "Eudoxus Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
}

%titleText {
  font-family: "Eudoxus Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

%buttonText {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

%caption1Text {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: $gray;
}

%caption2Text {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: $gray;
}

%menuText {
  font-family: "Eudoxus Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42;
}
