@import "../../scss/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  &:hover {
    // path,
    // rect,
    // svg,
    // circle {
    //   fill: $creme;
    // }

    // .item_chevron path {
    //   fill: transparent;
    //   stroke: $creme;
    // }
  }
  padding: 12px 15px;
}
.item {
  border-radius: 8px;

  &_itemBtn {
    cursor: pointer;
    display: flex;
    width: 100%;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    color: $text;
    background-color: transparent;
    border-radius: 8px;
    border: none;

    &:hover {
      background-color: $light_creme;
    }
  }

  &_itemBtnExpanded {
    background-color: $black;
    color: $creme;

    &:hover {
      background-color: $black;
      color: $creme;
    }

    path,
    rect,
    svg,
    circle {
      fill: $creme;
    }

    .item_chevron path {
      fill: transparent;
      stroke: $creme;
    }

    .item_chevron {
      transform: rotate(180deg);
    }
  }

  &_itemContent {
    border-radius: 8px;
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    color: $creme;

    &:hover {
      &_itemPanel {
        color: $creme;
      }
    }
  }

  &_chevron {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }
}
