.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_logo {
    margin: 50px 0;
  }

  &_box {
    width: 400px;
    padding: 30px;
    border-radius: 24px;
    background-color: white;
    box-shadow: 0px 15px 80px 0px rgba(227, 230, 236, 0.8);

    &_input {
      margin-bottom: 20px;
    }
  }

  &_error {
    color: red;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px;
  }
}
