@import "../../../../../../../../scss/variables";

.wrapper {
  padding: 14px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e9e8e6;

  &_name {
    flex: 0 0 40%;
    font-size: 14px;
    font-weight: 700;
  }

  &_file {
    display: flex;
    justify-content: space-between;
    flex: 1;

    &_uploadedFileWrapper {
      display: flex;
      align-items: center;
      gap: 15px;

      &_iconWrapper {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: $creme;
      }

      &_fileNameAndSizeWrapper {
        display: flex;
        flex-direction: column;

        :first-child {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: $black;
          font-size: 14px;
          font-weight: 700;
        }

        :nth-child(2) {
          color: $gray;
          font-weight: 400;
        }
      }
    }
  }

  label {
    padding: 11px 24px 11px 22px;
    display: flex;
    gap: 8px;
    border: 1px solid #e9e8e6;
    border-radius: 8px;
    cursor: pointer;
  }

  input {
    display: none;
  }

  &_viewAndDeleteWrapper {
    display: flex;
    gap: 65px;

    :first-child {
      color: $primary;
      font-weight: 700;
      font-size: 14px;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;

      svg {
        path {
          fill: #e53e3e;
        }
      }
    }
  }
}
