@import "scss/variables.scss";
@import "scss/typography.scss";

.chat {
  background-color: $white;
  box-shadow: 0px 27px 72px 0px rgba(27, 27, 29, 0.06);
  border-radius: 10px;

  &_header {
    padding: 19px 32px;
    font-weight: 700;
    border-bottom: 1px solid $half_gray;
  }

  &_viewport {
    padding: 0 0 20px;
    height: 572px;

    display: flex;
    flex-direction: column-reverse;
    justify-content: end;

    overflow: scroll;
  }

  &_messagesList {
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    padding: 0 16px;
    overflow-y: auto;
    min-height: 100%;
  }

  &_messagesGroupTitle {
    @extend %menuText;
    margin-bottom: 2px;

    &__customer {
      color: $primary;
      text-align: right;
    }
    &__driver {
      color: $darkgray;
      text-align: left;
    }
  }

  &_empty {
    @extend %bodyText;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: $gray;
  }
}
