@import "scss/variables.scss";
@import "scss/typography.scss";

.alert {
  // position: absolute;
  // z-index: 2;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  max-width: 500px;
  // padding: 32px;
  // height: fit-content;
  // background-color: $white;
  text-align: center;
  // border: 1px solid $gray;
  // border-radius: 8px;

  &_question {
    @extend %h5Text;
    margin-bottom: 15px;
  }

  &_buttons {
    display: flex;
    justify-content: center;
    gap: 35px;
  }
}

// .overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.75);
//   z-index: 3;
// }
