@import "scss/variables.scss";
@import "scss/typography.scss";

.title {
  @extend %h5Text;
  margin-bottom: 22px;
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 20px;

  transition: background-color $transitionSetting;

  &:hover {
    cursor: pointer;
    background-color: $light;
  }
}
