@import "scss/variables.scss";
@import "scss/typography.scss";

.options {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: absolute;
  right: -15px;
  top: 70%;
  z-index: 2;
  width: 109px;
  padding: 10px;
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0px 18px 50px 0px rgba(0, 0, 0, 0.14);
}

.optionBtn {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 6px 8px;
  border-radius: 10px;
  min-width: 89px;
  color: $black;
  @extend %buttonText;
  // line-height: 32px;

  &__delete {
    color: $red;
  }

  &:hover {
    background-color: $light;
    cursor: pointer;
  }
}
