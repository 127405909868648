@import "scss/variables.scss";
@import "scss/typography.scss";

.content {
  &_description {
    @extend %bodyText;
    display: inline-block;
    color: black;
    margin-bottom: 13px;
  }
}

.holidaysForm {
  &_rows {
    margin-bottom: 28px;
  }

  &_item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 15px 5px;

    border-bottom: 1px solid $lightGray;
  }

  &_toDateCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &_deleteBtn {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin-right: -10px;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color $transitionSetting;

    &:hover {
      background-color: $lightGray;
      cursor: pointer;
    }
  }

  &_buttons {
    display: flex;
    gap: 10px;
  }
}
