.wrapper {
  position: relative;
  border-radius: 50px;
  background-color: rgba(226, 228, 231, 1);
  overflow: hidden;

  &_image {
    position: absolute;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &_skeletone {
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}
