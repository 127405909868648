@import "scss/variables.scss";

.link {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding: 8px 18px;
  text-decoration: none;
  color: $gray;
  font-family: "Eudoxus Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  transition: background-color $transitionSetting;

  &:hover {
    background-color: $half_gray;
  }

  &__active {
    color: $creme;
    background-color: $black;
    cursor: default;

    &:hover {
      background-color: $black;
    }
  }
}

.counterValue {
  padding: 0 6px;
  border-radius: 30px;
  background-color: $lightGray;
  color: $gray;
  transition: background-color $transitionSetting, color $transitionSetting;

  .link__active & {
    background-color: $white;
    color: $black;
  }
}
