@import "../../scss/variables.scss";

.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #e4e3e0;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  &__active {
    border: 2px solid $primary;
    background-color: $primary;
  }
  &__disabled {
    cursor: default;
  }
}
