@import "scss/variables.scss";
@import "scss/typography.scss";

.title {
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  font-size: 13px;
  font-weight: 700;
  color: $medium_gray;

  :first-child {
    flex: 0 0 40%;
  }

  :nth-child(2) {
    flex: 1;
  }
}

.emptyListPlaceholder {
  @extend %bodyText;
  font-size: 16px;
  text-align: center;
}
