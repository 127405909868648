@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &_left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &_title {
    font-size: 32px;
    font-weight: 700;
    color: $black;
    margin: 0;
  }
  &_count {
    margin: 10px 0 0 5px;
    color: $gray;
    font-weight: 700;
  }
}
