@import "../../scss/variables.scss";

.wrapper {
  position: relative;

  &_label {
    font-family: "Inter";
    margin-bottom: 10px;
    color: $gray;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  &_select {
    font-weight: 500;
  }

  &_selector {
    &:focus {
      outline: none;
    }
  }

  &_error {
    position: absolute;
    bottom: -16px;
    color: red;
    font-size: 13px;
    line-height: 16px;
    margin-top: 5px;
  }
}
