.loader {
  &_container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.4);
  }
}
