@import "scss/variables.scss";
@import "scss/typography.scss";

.feedbackPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: 28px;
  }

  &_topInputs {
    display: flex;
    gap: 26px;
    justify-content: space-between;
    align-items: flex-end;
  }

  &_search {
    width: 430px;
  }

  &_datePikers {
    display: flex;
    gap: 26px;
  }

  &_table {
    flex-grow: 1;
  }
}

.feedbackRowItem {
  padding: 10px 5px;
  border-bottom: 1px solid $lightGray;
  display: flex;
  align-items: center;
  min-height: 60.75px;
  gap: 25px;
  cursor: pointer;
  transition: background-color $transitionSetting;

  &:hover {
    background-color: $half_gray;
  }

  &_reporterCell {
    @extend %titleText;
  }

  &_commonCell {
    @extend %bodyText;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  &_lastCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.feedbackModal {
  width: 464px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  &_title {
    @extend %h6Text;
  }

  &_booking {
    @extend %bodyText;
    text-decoration: none;
    color: $black;
  }

  &_reporterAndDate {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &_reporter {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &_subTitle {
    @extend %caption1Text;
    font-family: "Eudoxus Sans", sans-serif;
  }
  &_reporterName {
    @extend %titleText;
    text-decoration: none;
    color: $black;
  }
  &_reporterContacts {
    @extend %bodyText;
  }

  &_date {
    @extend %bodyText;
  }

  &_comment {
    margin-top: 5px;
    @extend %bodyText;
    font-size: 16px;
  }
  &_link {
    transition: color $transitionSetting;

    &:hover {
      color: $primary;
    }
  }
}
