$lightgray: #808191;
$lightgray200: #e9e8e6;
$neutralgray: #e4e4e4;
$gray: #e3e6ec;
$hardblack: #11142d;
$black: #1c1c1c;
$white: #fff;
$main: #54585f;
$label: #b2b3bd;
$mediumgray: #e4e5e9;
$darkgray: #a1a2a8;
$light: #f8f8f8;

$creme: #edd5b5;
$light_creme: #fbf2e4;
$primary: #aa8768;
$blue: #3f8cff;

$medium_gray: #bbb2a8;
$green: #4cc045;
$red: #e53e3e;
$pink: #ffc0cb;
$text: #5f5a54;
$gray: #8b857e;
$half_gray: #f4f2f0;
$border_gray: #e4e3e0;

$orange: #ffbc3f;
$purple: #854ddf;
$lightGray: #e9e8e6;
$darkGray: #a49e97;

$transitionSetting: 0.2s linear;
