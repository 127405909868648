@import "scss/variables.scss";
@import "scss/typography.scss";

.bookingsTable {
  flex-grow: 1;
}

.bookingRow {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 20px 5px;
  font-size: 14px;
  border-bottom: 1px solid $lightGray;
  color: $black;
  text-decoration: none;
  transition: background-color $transitionSetting;

  &:hover {
    background-color: $half_gray;
    cursor: pointer;
  }

  &_primaryText {
    font-weight: 700;
  }
  &_secondaryText {
    color: $gray;
  }
  &_detailsIcons {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &_detailsIconItem {
    display: flex;
    gap: 5px;
    font-weight: 700;
    align-items: center;
  }
  &_date {
    margin-top: 10px;
  }
  &_lastCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
