.row {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.container {
  position: relative;

  &_error {
    position: absolute;
    bottom: -16px;
    color: red;
    font-size: 13px;
    line-height: 16px;
    margin-top: 5px;
  }
}
