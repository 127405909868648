@import "scss/variables.scss";
@import "scss/typography.scss";

.driverAndVehicle {
  border: 1px solid $medium_gray;
  border-radius: 8px;
  padding: 15px 15px;
  height: 96px;

  &__placeholder {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: $gray;
  }

  &_link {
    &:hover {
      background-color: $half_gray;
      cursor: pointer;
    }
  }

  &_data {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &_name {
    font-weight: 700;
    flex-basis: 35%;
  }

  &_goNext {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    flex-grow: 1;
  }
  &_arrowIcon {
    transform: rotate(90deg);
  }
}
