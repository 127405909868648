@import "scss/variables.scss";
@import "scss/typography.scss";

.customer {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  gap: 24px;
  padding: 15px 5px;
  font-size: 14px;
  border-bottom: 1px solid $lightGray;
  color: $black;
  text-decoration: none;
  transition: background-color $transitionSetting;

  &:hover:not(:disabled) {
    background-color: $half_gray;
    cursor: pointer;
  }

  &_dataCell {
    min-width: min-content;
  }

  &_name {
    @extend %titleText;
  }

  &_lastCell {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
