@import "scss/variables.scss";
@import "scss/typography.scss";

.status {
  position: relative;

  &_labelBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 4px 10px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 13px;
    width: fit-content;
    cursor: pointer;
  }

  &_dropdown {
    position: absolute;
    top: 30px;
    padding: 10px;
    background-color: $white;
    width: 123px;
    box-shadow: 0px 18px 50px 0px rgba(0, 0, 0, 0.14);
    z-index: 2;
    border-radius: 15px;
  }
  &_option {
    min-width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
