@import "scss/variables.scss";
@import "scss/typography.scss";

.areasPage {
  &_search {
    max-width: 430px;
  }
  &_content {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
}

.areaRowItem {
  position: relative;
  padding: 15px 5px;
  display: flex;
  gap: 24px;
  border-bottom: 1px solid $lightGray;
  @extend %bodyText;

  &_nameCell {
    @extend %menuText;
  }

  &_statusCell {
    // padding-right: 45px;
  }

  &_statusLabel {
    display: inline-block;
    padding: 3px 10px;
    background-color: $medium_gray;
    border-radius: 8px;
    margin-right: 45px;
    color: $white;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.38;
    cursor: default;

    &__active {
      background-color: $green;
    }
  }

  &_dotsBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    transition: background-color $transitionSetting;

    &:hover {
      background-color: $lightGray;
      cursor: pointer;
    }
  }
}

.areaForm {
  width: 902px;
  display: flex;
  flex-direction: column;
  gap: 22px;

  &_mapContainer {
    height: 476px;
    background-color: gray;
  }

  &_buttonsContainer {
    display: flex;
    gap: 10px;
  }
}

.markerLabel {
  @extend %menuText;
  margin-bottom: 8px;
}
