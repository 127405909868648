.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  &_empty {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
    color: black;
  }

  &_pagination {
    margin-top: 20px;
  }
}
