@import "scss/variables.scss";
@import "scss/typography.scss";

.chatMessage {
  display: flex;

  &__myMessage {
    justify-content: end;
  }

  &_body {
    min-width: 100px;
    max-width: 292px;
    padding: 10px 10px 15px;
    background-color: $lightGray;
    border-radius: 4px;
    position: relative;

    &__myMessage {
      background-color: $darkGray;
    }
  }

  &_textContent {
    @extend %bodyText;

    &__myMessage {
      color: $white;
    }
  }

  &_imageContainer {
    width: 252px;
    height: 180px;
    margin-bottom: 2px;
    position: relative;
  }
  &_imageLink {
    &:hover {
      cursor: pointer;
    }
  }
  &_imageSkeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &_timeAndStatus {
    font-size: 10px;
    line-height: 1.4;
    color: $medium_gray;
    position: absolute;
    bottom: 4px;
    right: 4px;
    display: flex;
    align-items: center;

    &__myMessage {
      color: $lightGray;
    }
  }
}
