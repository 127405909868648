@import "../../../../scss/variables.scss";

.wrapper {
  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    color: $black;

    &_info {
      display: flex;
      //   align-items: center;

      &_avatar {
        margin-right: 20px;
      }

      &_name {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 20px;

        span {
          font-size: 32px;
          font-weight: 700;
        }
      }

      &_location {
        font-size: 16px;
        font-weight: 400;
      }
    }

    &_text {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: #1C1C1C;
    }

    &_statistic {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &_item {
        display: flex;
        align-items: center;
        margin-left: 20px;

        &_label {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: $lightgray;
        }

        &_value {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          color: $black;
        }

        &_line {
          width: 1px;
          height: 25px;
          background-color: #e3e6ec;
          margin-left: 20px;
        }

        &_rating {
          display: flex;
          align-items: center;

          &_icon {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  &_section {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 2px solid $light;

    &_title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: $black;
      margin-bottom: 20px;
    }
  }

  &_cover {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $main;
  }

  &_about {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $main;

    &_category {
      margin-bottom: 25px;
    }
  }

  &_history {
    margin-bottom: 20px;
  }

  &_documentsMainInputs {
    display: flex;
    gap: 20px;
    margin-top: 25px;

    > * {
      width: 100%;
    }
  }
}
