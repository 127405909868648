@import "../../../scss/variables.scss";

.wrapper {
  display: flex;
  height: 100vh;
  position: relative;

  &_menu {
    width: 260px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    &_logo {
      padding: 51px 0 30px;
      text-align: center;
    }

    &_nav {
      padding: 0 20px;
      margin-left: 16px;
      flex: 1;

      &_item {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 12px 15px;
        border-radius: 8px;
        color: $text;
        cursor: pointer;

        &_acordionText {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          margin-left: 16px;
        }

        &:hover {
          background-color: $light_creme;
        }

        &_active {
          background-color: $black;
          color: $creme;

          &:hover {
            background-color: $black;
          }

          path,
          rect,
          svg,
          circle {
            fill: $creme !important;
          }

          .wrapper_menu_nav_subText {
            color: white;
          }

          &__sub {
            background-color: $light_creme;

            .wrapper_menu_nav_subText {
              color: $black;
            }
          }
        }

        &_alert {
          // background-color: red;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-grow: 1;

          rect {
            fill: $red !important;
          }
          path {
            fill: $white !important;
          }
        }

        &_icon {
          width: 24px;
          margin-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &_accordion_icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &_text {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
        }

        &_openText {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          margin-left: 16px;
          color: $text;
        }
      }

      &_sub {
        &:hover {
          background-color: $light_creme;
        }
      }
    }

    &_profile {
      display: flex;
      align-items: center;
      padding: 30px 25px;
      cursor: pointer;

      &:hover {
        background-color: $neutralgray;
      }

      &_info {
        flex: 1;
        margin: 0 10px;

        &_name {
          font-size: 14px;
          line-height: 20px;
          color: $black;
          font-weight: 700;
        }
        &_description {
          font-size: 13px;
          line-height: 18px;
          color: $lightgray;
          font-weight: 700;
        }
      }
    }
  }

  &_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 40px 50px;
    overflow: auto;
  }
}
