.users {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: 28px;
  }

  &_searchLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_searchInput {
    flex-basis: 430px;
  }
  &_actionButtons {
    display: flex;
    gap: 10px;
  }
  &_table {
    flex-grow: 1;
  }
  &_pagination {
    margin-top: 28px;
  }
}
