.vehicles {
  background-color: #ffff;
  padding: 30px 32px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0px 27px 17px 0px rgba(27, 27, 29, 0.06);

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &_search {
    width: 430px;
  }

  &_content {
    background-color: red;
  }
}
