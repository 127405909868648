@import "scss/variables.scss";
@import "scss/typography.scss";

.bookingPage {
  &_header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &_headerBtns {
    display: flex;
    gap: 10px;
  }
  &_backBtn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  &_title {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 700;
  }
  &_id {
    font-size: 14;
    color: $gray;
  }

  &_contentContainer {
    display: flex;
    gap: 30px;
  }

  &_contentColumn {
    &__main {
      flex-grow: 1;
    }

    &__chat {
      flex-basis: 362px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  &_mainContent {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  &_mainContentSubtitle {
    @extend %titleText;
    margin-bottom: 10px;
  }
  &_mainContentNoteBlock {
    width: "100%";
    border: 1px solid $medium_gray;
    border-radius: 8px;
    padding: 15px 15px;
  }
  &_mainContentNote {
    font-size: 14px;
    line-height: 1.42;

    &__empty {
      color: $gray;
    }
  }
}

.mainInfo {
  width: 100%;
  border: 1px solid $medium_gray;
  border-radius: 8px;

  &_topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 15px;
    border-bottom: 1px solid $medium_gray;

    &__client {
      display: block;
      padding: 0;
    }
  }

  &_flightNumberRow {
    padding: 12px 15px;
    display: flex;
    align-items: center;
    gap: 15px;

    &_title {
      font-size: 12px;
      line-height: 1.33;
      color: $gray;
    }

    &_value {
      @extend %titleText;
    }
  }

  &_topRigthSubBlock {
    display: flex;
    gap: 15px;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
  }
  &_topLeftSubBlock {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  &_vehicleCategory {
    font-size: 16px;
    font-weight: 700;
  }
  &_optionsList {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &_optionsItem {
    height: 32px;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    background-color: $light_creme;
    font-weight: 700;
    font-size: 14px;
    color: $black;
  }
  &_clientInfoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    transition: background-color $transitionSetting;

    &:hover {
      background-color: $half_gray;
      cursor: pointer;
    }
  }
  &_clientDataBlock {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &_clientPrimarydata {
    font-weight: 700;
  }

  &_clientSecondaryData {
    @extend %bodyText;
    color: $gray;
  }
  &_pointRow {
    padding: 10px 15px 10px 0;
    margin-left: 50px;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid $medium_gray;
    }
  }

  &_pointRowIcon {
    position: absolute;
    left: -35.5px;
    top: 18px;
  }

  &_mainAddress {
    font-weight: 700;
    font-size: 16px;
  }
  &_secondaryAddress {
    font-size: 12px;
    color: $gray;
  }
}

.summaryBlock {
  width: "100%";
  border: 1px solid $medium_gray;
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &_extraTitle {
    font-size: 14px;
    line-height: 1.42;
    font-weight: 700;
    text-transform: uppercase;
  }

  &_separatorLine {
    width: 100%;
    height: 1px;
    background-color: $medium_gray;
  }

  &_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 1.42;
  }

  &_rowTitle {
    &_subtitle {
      font-family: Inter;
      font-size: 12;
      line-height: 1.33;
      color: $gray;
      font-weight: 500;
    }
  }

  &_rowValue {
    &__accent {
      font-size: 16px;
      font-weight: 700;
    }
  }
  &_separatorLine {
    height: 1px;
    background-color: $half_gray;
  }
}

.paymentsList {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &_item {
    width: "100%";
    border: 1px solid $medium_gray;
    border-radius: 8px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &_itemTitle {
    font-size: 14px;
    font-weight: 700;
  }
  &_itemSubtitle {
    font-size: 12px;
    color: $gray;
  }
}
