@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  gap: 5px;
  flex-grow: 1;

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border_gray;
    border-radius: 8px;
    padding: 14px 5px;
    height: 48px;
    min-width: 48px;
    background-color: white;
    color: $black;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    transition: background-color $transitionSetting, color $transitionSetting,
      opacity $transitionSetting;

    &:hover {
      background-color: $half_gray;
      color: $black;
    }

    &_active {
      border: 1px solid $primary;
      background-color: $primary;
      color: $white;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }

    &_prevNext {
      border: 1px solid $black;
      background-color: $black;
      color: $creme;
      min-width: 81px;

      &:hover {
        background-color: $black;
        color: $creme;
        opacity: 0.8;
      }
    }

    &_transparent {
      background-color: transparent;
      padding: 14px 5px;
      min-width: 20px;
      cursor: default;
    }
  }
}
