@import "scss/variables.scss";
@import "scss/typography.scss";

.userPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &_backBtn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  &_title {
    @extend %h6Text;
    flex-grow: 1;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 46px;
  }

  &_headerButtons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &_contentContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex-grow: 1;
  }
}

.userData {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_userBlock {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &_userName {
    @extend %h5Text;
  }
  &_commonText {
    @extend %bodyText;
  }
}

.holderBlock {
  padding: 10px 15px;
  border: 1px solid $lightGray;
  border-radius: 8px;
  min-width: 254px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  max-height: 98px;

  &:hover {
    background-color: $half_gray;
    cursor: pointer;
  }

  &_title {
    @extend %caption2Text;
  }

  &_name {
    @extend %menuText;
  }
}
