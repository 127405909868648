@import "scss/variables.scss";
@import "scss/typography.scss";

.statisticsPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &_navigation {
    display: flex;
    justify-content: flex-end;
  }

  &_mainData {
    display: flex;
    justify-content: space-between;
    gap: 34px;
    margin-bottom: 30px;
  }

  &_mainLeftBlock {
    flex-basis: 20%;
    flex-shrink: 1;
  }

  &_mainRigthBlock {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 28px;
    max-width: 1000px;
  }

  &_blockTitle {
    font-size: 18px;
    line-height: 1.77;
    min-width: 180px;
  }

  &_totalRevenue {
    margin-top: 9px;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -1px;
  }

  &_totalSeparatorLine {
    min-width: 213px;
    height: 1px;
    background-color: $border_gray;
    margin: 15px 0;
  }

  &_blockSubTitle {
    font-size: 13px;
    line-height: 1.84;
  }

  &_todayStatisticContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_todayTotal {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }
  &_dailyChangingLabel {
    display: flex;
    align-items: center;
    gap: 4px;
    @extend %caption2Text;

    &__increase {
      color: $green;
    }

    &__decrease {
      color: $red;
    }
  }

  &_chartContainer {
    height: 180px;
  }

  &_midRow {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $border_gray;
    border-bottom: 1px solid $border_gray;
    margin: 0 -32px 25px;
  }

  &_midRowColumn {
    flex-basis: 33.33%;
    padding: 25px 30px;
  }

  &_midRowTotal {
    @extend %h5Text;
    margin-top: 5px;
  }

  &_midRowSeparatorLine {
    height: 1px;
    background-color: $border_gray;
    margin: 10px 0;
  }

  &_midRowTodayTotal {
    @extend %h6Text;
  }

  &_bottomBlockTitle {
    font-size: 18px;
    line-height: 1.77;
    margin-bottom: 10px;

    &__smallText {
      font-size: 14px;
      line-height: 20px;
      color: $gray;
    }
  }

  &_vehiclesRow {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
}

.vehiclesColumn {
  flex-grow: 1;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  &_category {
    font-size: 16px;
    font-weight: 700;
  }
  &_percentLabel {
    @extend %caption2Text;
    text-align: right;
    color: $primary;
    margin-bottom: 10px;
  }
}

.chartTooltip {
  width: 70px;
  height: 32px;
  background-image: url("../../../assets/images/chargeTooltip.svg");

  &_value {
    color: $white;
    text-align: center;
    font-size: 13px;
    line-height: 28px;
    font-weight: 700;
  }
}

.chartAxis {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: $gray;
}
