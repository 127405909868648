@import "../../scss/variables.scss";

.header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &_switcher {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  &_switcherTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &_switcherText {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &_container {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
  }

  &_arrow {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &_title {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: $black;
  }

  &_actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &_item {
      margin-left: 10px;
    }
  }

  &_links {
    display: flex;
    align-items: center;

    &_item {
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      color: $lightgray;
      margin-left: 24px;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        color: $black;
      }

      &_active {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: $black;
      }
    }
  }
}
