@import "../../scss/variables.scss";
.btn {
  display: flex;
  justify-content: flex-end;
  flex: 0.4;
  color: "#fff";
  &_state {
    background-color: $medium_gray;
    padding: 3px 10px 4px 10px;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
  }
  &_active {
    background-color: $green;
  }
}
