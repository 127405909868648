@import "scss/variables.scss";
@import "scss/typography.scss";

.staffPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: 28px;
  }

  &_search {
    max-width: 430px;
  }
}

.staffRowItem {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px 5px;
  position: relative;
  border-bottom: 1px solid $lightGray;

  &_userCell {
    display: flex;
    align-items: center;
    gap: 15px;
    @extend %titleText;
  }

  &_emailCell {
    @extend %bodyText;
  }

  &_roleCell {
    @extend %menuText;
    text-transform: capitalize;
  }

  &_dotsBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    transition: background-color $transitionSetting;

    &:hover {
      background-color: $lightGray;
      cursor: pointer;
    }
  }

  &_options {
    position: absolute;
    right: -15px;
    top: 70%;
    z-index: 2;
    width: 109px;
    padding: 6px 8px;
    border-radius: 15px;
    background-color: $white;
    box-shadow: 0px 18px 50px 0px rgba(0, 0, 0, 0.14);
  }
}

.form {
  width: 508px;

  &_title {
    @extend %h6Text;
  }

  &_avatarField {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 22px 0;
    border-bottom: 1px solid $border_gray;
  }

  &_changeAvatarBtn {
    @extend %caption2Text;
    cursor: pointer;
  }

  &_fieldsContainer {
    padding: 22px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &_multiFieldRow {
    display: flex;
    gap: 10px;
  }

  &_multiFieldRowItem {
    flex-grow: 1;
  }
}
