@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 15px 5px;
  justify-content: space-between;
  border-bottom: 1px solid $lightGray;

  &_item {
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    &_title {
      font-size: 13px;
      line-height: 18px;
      font-weight: 700;
      color: $medium_gray;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 3px;

      &_active {
        color: $black;
      }
    }

    &_arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      color: $medium_gray;

      &_active {
        color: $black;
      }
    }
  }
}
