.loadController {
  flex-grow: 1;
  text-align: center;
  padding: 10px 0;

  &_spiner {
    width: 20px;
    height: 20px;
  }
}
