@import "scss/variables.scss";
@import "scss/typography.scss";

.content {
  &_description {
    @extend %bodyText;
    display: inline-block;
    color: black;
    margin-bottom: 13px;
  }
}

.form {
  &_buttons {
    display: flex;
    gap: 24px;
    margin-top: 24px;
  }
}

.priceRow {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 15px 5px;
  flex-shrink: 0;
  border-bottom: 1px solid $lightGray;
}

.priceRowCell {
  &__category {
    @extend %titleText;
    color: $black;
  }
}
