@import "../../scss/variables";

.toggle {
  width: 52px;
  height: 30px;
  background-color: #ccc;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 6px;

  &_active {
    background-color: $primary;
  }
  &_slider {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transition: 0.3s;
  }

  &_active &_slider {
    transform: translateX(100%);
  }
  &_inactive &_slider {
    transform: translateX(0);
  }
}
