@import "../../scss/variables.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  padding: 12px 24px;
  border-radius: 8px;
  padding: 12px 24px 12px 22px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  background-color: white;
  color: $black;
  cursor: pointer;
  border: 1px solid $lightgray200;
  transition: background-color $transitionSetting, color $transitionSetting,
    opacity $transitionSetting;

  &:hover {
    background-color: $half_gray;
    color: $black;
  }

  &_black {
    background-color: $black;
    color: $creme;
    border-color: $black;

    path {
      fill: $creme;
    }

    &:hover {
      background-color: $black;
      color: $creme;
      border-color: $black;
      opacity: 0.8;
    }
  }

  &_red {
    background-color: $red;
    color: white;
  }

  &_white {
    background-color: white;
    color: $black;
  }

  &_disable {
    background-color: $half_gray !important;
    border-color: $half_gray !important;
    color: $black !important;
    cursor: auto !important;
  }
}
