@import "../../scss/variables";

.wrapper {
  max-width: 508px;
  margin-top: 25px;
  width: 33vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  background-color: transparent;

  &_title {
    font-size: 16px;
  }

  &_photo {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    &_buttons {
      display: flex;
      gap: 10px;

      button,
      label {
        background-color: transparent;
        border: none;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
      }

      label {
        color: $gray;
      }

      input {
        display: none;
      }

      &_delete {
        padding: 0;
        color: $red;
      }
    }
  }

  &_line {
    margin-top: 10px;
    height: 1px;
    width: 100%;
    background-color: #e9e8e6;
  }

  &_firstfields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 15px;
  }

  &_homefields {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 15px;

    &_citycode {
      display: flex;
      gap: 20px;
      flex-direction: row;

      :nth-child(1) {
        flex-basis: calc(100%);
      }
    }
  }

  &_buttons {
    width: fit-content;
    margin-top: 10px;
    display: flex;
    gap: 10px;

    button {
      white-space: nowrap;
    }
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 700;
  color: $black;
}

.notification {
  &_container {
    background-color: #4cc045;
  }
}
