@import "../../scss/variables.scss";

.wrapper {
  padding: 12px;
  border-radius: 12px;
  background-color: white;
  position: relative;

  h5 {
    color: $hardblack;
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }

  &_closeButton {
    position: absolute;
    background: transparent;
    border: none;
    right: 10px;
    cursor: pointer;
  }
}
