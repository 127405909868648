@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  gap: 5px;

  &_item {
    padding: 8px 18px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all $transitionSetting;

    &_attention {
      margin-left: 5px;
    }

    &_title {
      transition: color $transitionSetting;
    }

    &:hover {
      background-color: $half_gray;
      color: $black;

      .wrapper_item_title {
        color: $black;
      }

      .wrapper_item_count {
        background-color: white;
        color: $black;
      }
    }

    &_active {
      background-color: $black;

      &:hover {
        background-color: $black;
        .wrapper_item_title {
          color: $creme;
        }
      }
    }

    &_title {
      color: $gray;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;

      &_active {
        color: $creme;
      }
    }

    &_count {
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      border-radius: 20px;

      background-color: $black;
      color: white;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;

      &_active {
        background-color: white;
        color: $black;
      }
    }
  }
}
