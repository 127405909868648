@import "../../../../../scss/variables.scss";

.wrapper {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5px;
  border-bottom: 1px solid $neutralgray;
  text-align: left;
  transition: background-color $transitionSetting;

  &:hover {
    cursor: pointer;
    background-color: $half_gray;
  }

  &_checkbox {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &_info {
    display: flex;
    align-items: center;
    flex: 5;
    overflow: hidden;

    &_avatar {
      margin-right: 10px;
    }

    &_name {
      font-size: 16px;
      font-weight: 700;
      color: $black;
    }

    &_type {
      font-size: 13px;
      font-weight: 400;
      color: $lightgray;
      line-height: 24px;
    }
  }
  &_category {
    flex: 2;
    overflow: hidden;
    color: $lightgray;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    &_orange {
      color: $orange;
    }
    &_blue {
      color: $blue;
    }
    &_purple {
      color: $purple;
    }
  }

  &_email {
    flex: 4;
    overflow: hidden;
  }

  &_emailLink {
    text-overflow: ellipsis;
    color: $black;
    text-decoration: none;
  }

  &_phone {
    flex: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_btn_state {
    color: #fff;
  }

  &_btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    flex: 3;
    overflow: hidden;

    &_status {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &_state {
      background-color: $medium_gray;
      margin-right: 24px;
      padding: 3px 10px 4px 10px;
      border-radius: 8px;
    }
    &_active {
      background-color: $green;
    }
    &_wrapper {
      display: flex;
      width: 24px;
      height: 24px;
      background-color: $primary;
      border-radius: 24px;
    }
  }
}
