@import "scss/variables.scss";
@import "scss/typography.scss";

.chatWidget {
  &_bubleContainer {
    position: relative;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 52px;
    height: 52px;
  }
  &_allUnreadCounter {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    min-width: 20px;
    background-color: $red;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    color: $white;
    @extend %caption2Text;
    font-weight: 600;
    transform: translate(30%, -20%);
  }

  &_bubble {
    width: 52px;
    height: 52px;
    border-radius: 100%;
    background-color: $black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_container {
    position: fixed;
    bottom: 30px;
    right: 30px;
    transform: scale(0);
    background-color: $white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    visibility: hidden;
    display: flex;
    flex-direction: column;
    transition: all $transitionSetting;
    height: 52px;
    width: 52px;
    z-index: 10;

    &.displayed {
      height: 75%;
      width: 375px;
      border-radius: 12px;
      visibility: visible;
      bottom: 90px;
      right: 20px;
      transform: scale(1);
    }
  }

  &_mainContent {
    flex-grow: 1;
    overflow-y: hidden;
  }
}

.chatHeader {
  flex-basis: 56px;
  flex-shrink: 0;
  height: 56px;
  background-color: $black;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 11px 16px;

  &_categoryLabel {
    @extend %titleText;
    width: 100%;
    text-align: center;
    color: $creme;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_backBtn {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &_clientName {
    @extend %menuText;
    color: $white;
  }
  &_bookingId {
    font-size: 12px;
    line-height: 1.33;
    color: $lightGray;
  }
  &_selectedChatData {
    flex-grow: 1;
  }
  &_completeBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
    @extend %menuText;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $creme;
    }
  }
}

.categoriesFooter {
  display: flex;
  flex-direction: row;
  min-height: 4.25rem;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  font-size: 12px;

  &_item {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    row-gap: 2px;
    color: $gray;

    &__selected {
      color: $black;

      cursor: default;
    }
  }
}

.messagesList {
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  height: 100%;

  &_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    padding: 0 16px 12px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_startDate {
    margin-bottom: 10px;
    @extend %caption1Text;
    text-align: center;
  }
}

.chatItemsList {
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  &_item {
    border-bottom: 1px solid $lightgray200;
    padding: 12px 16px;
    min-height: 66px;
    transition: background-color $transitionSetting;

    &:hover {
      background-color: $half_gray;
      cursor: pointer;
    }

    &_details {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;

      .user_info {
        font-size: 16px;
        font-weight: bold;
        color: $black;
      }

      .time_info {
        position: relative;
        bottom: 4px;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
      }
    }

    .booking_id {
      font-size: 14px;
      font-weight: 400;
      color: $gray;
    }

    .status_icon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-family: "Inter";
        color: $white;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

.newMessageFooter {
  position: relative;
  padding: 15px 16px 20px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.09);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &_textarea {
    width: 100%;
    outline: none;
    resize: none;
    border: 1px solid $lightGray;
    border-radius: 4px;
    // min-height: 44px;
    padding: 12px 48px;
    @extend %bodyText;
    color: $black;

    &:focus {
      border-color: $primary;
    }

    &::placeholder {
      @extend %bodyText;
      color: $darkGray;
    }
  }
  &_btn {
    position: absolute;
    z-index: 999;
    bottom: 20px;
    width: 48px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $primary;

    &:disabled {
      color: $darkGray;
      cursor: default;
    }

    &__image {
      left: 16px;
      input {
        display: none;
      }
    }
    &__send {
      right: 16px;
    }
  }
}
