@import "./scss//variables.scss";
@import "./scss/normalize.scss";

@font-face {
  font-family: "Eudoxus Sans";
  src: url("./assets/fonts/EudoxusSans/Variable/EudoxusSansGX.woff2")
      format("woff2"),
    url("./assets/fonts/EudoxusSans/Variable/EudoxusSansGX.woff2")
      format("woff");
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Eudoxus Sans", sans-serif;
  line-height: 1.42;
  font-weight: 400;
  color: $black;
  background-color: #fcfcfc;
  overflow-y: hidden;
}

p,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

input,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

button {
  background-color: transparent;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}
