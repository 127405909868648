@import "scss/variables.scss";
@import "scss/typography.scss";

.bookings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: 28px;
  }

  &_topLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_search {
    max-width: 430px;
  }
}

.statusLabel {
  padding: 4px 10px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  width: fit-content;
}

.modeHandler {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  text-align: right;

  &_title {
    font-weight: 700;
  }
}

.exportModal {
  min-width: 400px;

  &_datePikerContainer {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-bottom: 60px;
  }

  &_datePicker {
    flex-grow: 1;
  }
}
