@import "scss/variables.scss";

.typingIndicator {
  display: flex;
  align-items: center;
  gap: 10px;
  &_animation {
    display: flex;
    align-items: center;
    width: 20px;
  }

  &_userName {
    font-size: 12px;
    line-height: 1.33;
    color: $primary;
  }
}
