@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: $half_gray;

  &_icon {
    padding-left: 16px;
  }
  &_input {
    display: block;
    flex: 1;
    height: 48px;
    margin-left: 15px;
    background-color: $half_gray;
    border: none;
    font-family: "Eudoxus Sans", sans-serif;

    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $gray;
      font-family: "Eudoxus Sans", sans-serif;
    }

    &:focus {
      outline-width: 0;
    }
  }
}
