@import "../../../scss/variables.scss";

.wrapper {
  background-color: white;
  padding: 30px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 24px;

  &_tabs {
    margin-bottom: 28px;
  }

  &_header {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;

    &_input {
      width: 400px;
    }

    &_filter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 48px;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
}

.driversContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding: 30px 32px;
  border-radius: 10px;
  box-shadow: 0px 27px 17px 0px rgba(27, 27, 29, 0.06);
}
