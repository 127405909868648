@import "scss/variables.scss";

.counterInput {
  width: 100%;
  position: relative;

  &_input {
    height: 48px;
    width: 100%;
    border: 1px solid $medium_gray;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Eudoxus Sans", sans-serif;
    line-height: 20px;
    color: $black;
    padding: 0 34px 0 15px;
    text-align: left;
    background-color: transparent;
    resize: none;

    &:focus {
      outline: none;
      background-color: transparent;
      border: 1px solid $primary;
      border-color: $primary;
    }
    &__error {
      border-color: $red;
    }
  }

  &_buttons {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 2;
  }

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: background-color $transitionSetting;

    &:hover {
      background-color: $lightGray;
      cursor: pointer;
    }
  }

  &_error {
    color: $red;
    position: absolute;
    width: max-content;
    font-size: 13px;
  }
}
