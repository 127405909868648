@import "scss/variables.scss";
@import "scss/typography.scss";

.marker-icon {
  transform-origin: center center;
  transform: rotate(40deg); /* Change the degree value to set rotation */
}

.locatorPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: 28px;
  }

  &_map {
    height: 558px;
    background-color: $lightGray;
    border-radius: 10px;
    overflow: hidden;
  }
}

.driverRowItem {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px 5px;
  border-bottom: 1px solid $lightGray;
  cursor: pointer;
  transition: background-color $transitionSetting;

  &:hover {
    background-color: $lightGray;
  }

  &_idCell {
    @extend %titleText;
  }

  &_driverCell {
    display: flex;
    align-items: center;
    gap: 15px;
    @extend %titleText;
  }

  &_statusCell {
    display: flex;
    justify-content: flex-end;
  }

  &_statusLabel {
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.38;
    color: $white;
    background-color: $medium_gray;
    border-radius: 8px;

    &__active {
      background-color: $green;
      color: $white;
    }
  }
}

.markerLabel {
  @extend %menuText;
  margin-bottom: 5px;
}
