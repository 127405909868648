@import "../../scss/variables.scss";

.wrapper {
  position: relative;

  &_label {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $gray;
  }

  &_datepicker {
    width: 100%;

    &_calendar {
      display: flex !important;
      background-color: white !important;
    }

    &_day {
      background-color: transparent !important;
      border-radius: 5px;

      &:hover {
        background-color: $lightGray !important;
      }
    }

    &_day__active {
      background-color: $primary !important;

      &:hover {
        background-color: $primary !important;
      }
    }

    &_time {
      background-color: transparent !important;

      &:hover {
        background-color: $lightGray !important;
      }

      &__active {
        background-color: $primary !important;

        &:hover {
          background-color: $primary !important;
        }
      }
    }
  }

  &_input {
    position: relative;
    display: block;
    height: 48px;
    width: 100%;
    border: 1px solid $medium_gray;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Eudoxus Sans", sans-serif;
    line-height: 20px;
    color: $black;
    padding: 0 30px 0 13px;
    text-align: left;
    background-color: transparent;
    resize: none;

    &_cursor {
      cursor: pointer;
    }

    &_calendar {
      outline: none;
    }

    &_container {
      position: relative;
      width: 100%;
    }

    &_icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &_error {
      border-color: $red !important;

      &_message {
        position: absolute;
        bottom: -15px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $red;
      }
    }

    &_focused {
      outline: none;
      background-color: transparent;
      border: 1px solid $primary;
      border-color: $primary;
    }

    &_wrapper {
      position: relative;
    }

    &_secure {
      display: flex;
      align-items: center;
      position: absolute;
      right: 23px;
      top: 0;
      height: 100%;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: $label;
      cursor: pointer;
      background-color: transparent;
      border: none;

      &:disabled {
        cursor: auto;
      }
    }
  }

  &_textarea {
    padding-top: 14px;
    padding-bottom: 14px;
    height: 76px;
    &__error {
      border-color: red;
    }
  }

  &_error {
    position: absolute;
    bottom: 0px;
    transform: translateY(100%);
    left: 0;
    color: red;
    font-size: 13px;
    line-height: 16px;
    margin-top: 5px;
  }
}
