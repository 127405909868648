@import "scss/variables.scss";
@import "scss/typography.scss";

.content {
  &_description {
    @extend %bodyText;
    display: inline-block;
    color: black;
    margin-bottom: 13px;
  }
}

.row {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 17px 5px;
  font-size: 14px;
  border-bottom: 1px solid $lightGray;

  &_dotsBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    transition: background-color $transitionSetting;

    &:hover {
      background-color: $lightGray;
      cursor: pointer;
    }
  }
}

.priceCell {
  display: flex;
  flex-direction: column;
  gap: 2px;

  &_rowItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 190px;
    gap: 5px;
  }

  &_priceValue {
    flex-basis: 78px;
    font-weight: 700;
  }
}

.addItemBtn {
  margin-top: 28px;
}

.form {
  width: 508px;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.prices {
  display: flex;
  gap: 10px;
  padding-bottom: 22px;
  border-bottom: 1px solid $lightGray;
}

.modalFormBtns {
  display: flex;
  gap: 10px;
}
